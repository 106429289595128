import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Metadata from '@/models/livereport/metadata';
import ScheduledMetadata from '@/models/livereport/scheduledMetadata';
import Pagination from '@/models/pagination';
import eventBus from '@/eventBus/eventBus';

export default defineStore('liveReportMetadataStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const liveReportMetadataStore = ref({
    metadata: {
      data: [] as Metadata[],
      pagination: {} as Pagination,
    },
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('liveReportMetadataStore');
    liveReportMetadataStore.value.metadata = {
      data: [] as Metadata[],
      pagination: {} as Pagination,
    };
  };

  /*
    Methods
  */
  const getAllReports = async (account = '*') => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    return axios.get(`/merchants/livereport/data/accounts/${account}`, config)
      .then((response) => {
        if (response.status === 204) { // No content
          return {
            data: [] as Metadata[],
          };
        }

        if (response.status === 403) {
          throw new Error('access_denied');
        }

        const { columns, rows, pagination } = response.data;
        const metadata = Metadata.fromApiResponse(columns, rows);

        if (metadata.length === 0) {
          return liveReportMetadataStore.value.metadata;
        }

        liveReportMetadataStore.value.metadata.data = metadata;
        liveReportMetadataStore.value.metadata.pagination = pagination;
        return liveReportMetadataStore.value.metadata;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const deleteScheduledReport = async (id: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      _id: id,
    };

    return axios.post('/merchants/livereport/agenda/delete', payload, config)
      .then((response) => {
        if (response.status !== 202) {
          throw new Error('Erro ao remover agendamento. Recarregue a página e tente novamente.');
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const getAllScheduledReports = async (account = '*', page = 1, pageLimit = 100) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      account,
      page,
      pageLimit,
    };

    return axios.post('/merchants/livereport/agenda', payload, config)
      .then((response) => {
        console.log('response', response);
        if (response.status === 204) {
          return [] as Metadata[];
        }
        console.log('after 204');
        if (response.status !== 200 && response.status !== 201) {
          throw new Error('Erro ao agendar relatório');
        }

        const { columns, rows, pagination } = response.data;
        const metadata = ScheduledMetadata.fromApiResponse(columns, rows);

        if (metadata.length === 0) {
          return [] as Metadata[];
        }

        return metadata;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const scheduleLiveReport = async (params: {
    account: string,
    liveDate: Date,
    durationInMinutes: number,
    platform: string,
    name: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      account: params.account,
      live_date: params.liveDate,
      duration_in_minutes: parseInt(`${params.durationInMinutes}`, 10),
      platform: params.platform,
      name: params.name,
    };

    return axios.post('/merchants/livereport/agenda/add', payload, config)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          throw new Error('Erro ao agendar relatório');
        }

        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  /*
    Hooks
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('liveReportMetadataStore')) {
    liveReportMetadataStore.value = JSON.parse(localStorage.getItem('liveReportMetadataStore') || '{}');
  }

  watch(liveReportMetadataStore, (merchantsVal) => {
    localStorage.setItem('liveReportMetadataStore', JSON.stringify(merchantsVal));
  }, {
    deep: true,
  });

  return {
    liveReportMetadataStore,
    resetStore,
    getAllReports,
    getAllScheduledReports,
    deleteScheduledReport,
    scheduleLiveReport,
  };
});
